import Head from "next/head";
import styles from "./index.module.css";
import { FooterComponent } from "@components/layout/footer";
import Link from "next/link";

export default function Home() {
    return (
        <div className={styles.container}>
            <Head>
                <title>Your department tools</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <main className={styles.main}>
                <h1 className={styles.title}>
                    Welcome to <Link href="/">Your Tools!</Link>
                </h1>

                <div className={styles.grid}>
                    <Link href="/events">
                        <div className={styles.card}>
                            <h2>Company events &rarr;</h2>
                            <p>Find event and grab detailed info</p>
                        </div>
                    </Link>
                    <Link href="/me">
                        <div className={styles.card}>
                            <h2>About me &rarr;</h2>
                            <p>Your authentication information</p>
                        </div>
                    </Link>
                </div>
            </main>

            <footer className={styles.footer}>
                <FooterComponent />
            </footer>
        </div>
    );
}
