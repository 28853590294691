import { FC, PropsWithChildren } from "react";
import styles from "./footer.module.scss";

export const FooterComponent: FC<PropsWithChildren<unknown>> = (props) => {
    return (
        <div className={styles.container}>
            <div>This is footer</div>
            <div>{props.children}</div>
        </div>
    );
};
